<div class="modal-header">
  <h5 class="modal-title">Creation Fee</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>

<div class="modal-body text-center">
  <div class="row g-3" [formGroup]="form">
    <div class="col-6">
      <mdb-form-control>
        <input mdbInput type="text" formControlName="name" id="name" class="form-control"/>
        <label mdbLabel class="form-label" for="name">Name *</label>
      </mdb-form-control>
    </div>
    <div class="col-6">
      <mdb-form-control>
        <input mdbInput type="text" formControlName="description" id="description" class="form-control"/>
        <label mdbLabel class="form-label" for="description">Description</label>
      </mdb-form-control>
    </div>
    <div class="col-6">
      <mdb-form-control>
        <mdb-select formControlName="type" id="type">
          <mdb-option *ngFor="let option of typeOptions" [value]="option.value">{{ option.label }}</mdb-option>
        </mdb-select>
        <label mdbLabel class="form-label" for="type">Type *</label>
      </mdb-form-control>
    </div>
    <div class="col-6">
      <mdb-form-control>
        <input mdbInput type="number" formControlName="value" id="value" placeholder="Value *" class="form-control"/>
      </mdb-form-control>
    </div>
    <div class="col-6">
      <mdb-form-control>
        <mdb-select formControlName="tag" id="tag">
          <mdb-option *ngFor="let option of tagOptions" [disabled]="option.disabled" [value]="option.value">{{ option.label }}</mdb-option>
        </mdb-select>
        <label mdbLabel class="form-label" for="tag">Tag *</label>
      </mdb-form-control>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-danger btn-sm"
    (click)="close()"
  >Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="submitForm()"
  >Creation
  </button>
</div>
