import {Component, EventEmitter, Output} from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";
import {IOption} from "../../interfaces/form.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IDynamicFeeInterfaces} from "../../interfaces/dynamic-fee.interfaces";
import {AlertService} from "../../alert/alert.service";

@Component({
  selector: 'ultrapay-create-fee',
  templateUrl: './create-fee.component.html',
  styleUrl: './create-fee.component.scss'
})
export class CreateFeeComponent {
  typeOptions: IOption[] = [
    {value: 'fixed', label: 'Fixed'},
    {value: 'percentage', label: 'Percentage'}
  ];
  tagOptions: IOption[] = [
    {value: 'due', label: 'Due'},
    {value: 'retained', label: 'Retained', disabled: true},
  ];

  @Output() submit: EventEmitter<IDynamicFeeInterfaces> = new EventEmitter<IDynamicFeeInterfaces>();

  form: FormGroup = new FormGroup({})

  constructor(private modalRef: MdbModalRef<CreateFeeComponent>, private alertService: AlertService) {
    this.initForm()
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      type: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
      tag: new FormControl('', Validators.required),
    });
  }

  close(dynamicFee?: IDynamicFeeInterfaces) {
    this.modalRef.close(dynamicFee);
  }

  submitForm() {
    if (this.form.invalid) {
      this.alertService.openErrorAlert('Please fill all required fields');
      return
    }
    this.close(this.form.value)
  }
}
