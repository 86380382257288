<div class="modal-header">
  <h5 class="modal-title">MID Selection</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body text-center">
  @if (!loading) {
    <div class="row g-3" [formGroup]="form">
      <div class="col-6">
        <mdb-form-control>
          <mdb-select [displayedLabels]="1" formControlName="superMerchant" [highlightFirst]="false" [multiple]="true" [filter]="true" placeholder="Super merchant" id="sMerchant">
            <mdb-select-all-option>Select all</mdb-select-all-option>
            <mdb-option *ngFor="let option of superMerchantsOption" [value]="option.value">{{ option.label }}</mdb-option>
          </mdb-select>
          <label mdbLabel class="form-label" for="sMerchant">Super merchant</label>
        </mdb-form-control>
      </div>
      <div class="col-6">
        @if (!loadingMerchant) {
          <mdb-form-control>
            <mdb-select [displayedLabels]="1" formControlName="merchant" [highlightFirst]="false" [multiple]="true" [filter]="true" placeholder="Merchant" id="merchantsOption">
              <mdb-select-all-option>Select all</mdb-select-all-option>
              <mdb-option *ngFor="let option of merchantsOption" [value]="option.value">{{ option.label }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" for="merchantsOption"> {{ role === ERole.OPERATOR ? 'Operator' : 'Merchant'}}</label>
          </mdb-form-control>
        } @else {
          <ultrapay-spinner></ultrapay-spinner>
        }
      </div>
      <div class="col-6">
        @if (!loadingGroup) {
          <mdb-form-control>
            <mdb-select [displayedLabels]="1" formControlName="group" [highlightFirst]="false" [multiple]="true" [filter]="true" placeholder="Group" id="group">
              <mdb-select-all-option>Select all</mdb-select-all-option>
              <mdb-option *ngFor="let option of groupsOption" [value]="option.value">{{ option.label }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" for="group">Group</label>
          </mdb-form-control>
        } @else {
          <ultrapay-spinner></ultrapay-spinner>
        }
      </div>
      <div class="col-6">
        @if (!loadingMid) {
          <mdb-form-control>
            <mdb-select [displayedLabels]="1" formControlName="mid" [highlightFirst]="false" [multiple]="true" [filter]="true" placeholder="Mid" id="mid">
              <mdb-select-all-option>Select all</mdb-select-all-option>
              <mdb-option *ngFor="let option of midsOption" [value]="option.value">{{ option.label }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" for="mid">Mid</label>
          </mdb-form-control>
        } @else {
          <ultrapay-spinner></ultrapay-spinner>
        }
      </div>
    </div>
  } @else {
    <ultrapay-spinner></ultrapay-spinner>
  }
</div>
<div class="modal-footer">
  <button
    [disabled]="form.invalid"
    type="button"
    class="btn btn-primary btn-sm"
    (click)="submitFilter()"
  >Filter
  </button>
</div>
