import { Component } from '@angular/core';
import {ModalService} from "../../../@core/services/modal.service";

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html'
})
export class LoadingModalComponent {
  text: string | null = null;

  loadingText$ = this.modalService.loadingText$;
  constructor(private modalService: ModalService) {
    this.loadingText$.subscribe(text => {
      if (text) this.text = text
    });
  }

}
