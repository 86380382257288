import moment from "moment"
import { IDateRange, IDateRangeUnix } from "../interfaces/filter-button-group.interface";
import { AlertService } from "../alert/alert.service";

export const getUnixFormat = (date: Date): number | undefined => {
    return moment(date)?.unix() ? moment(date)?.unix() : undefined;
}

export const getRangeUnixFormat = (dateRange: IDateRange): IDateRangeUnix => {
    return {
        startDate: getUnixFormat(dateRange?.startDate),
        endDate: getUnixFormat(dateRange?.endDate),
    }
}

export const getReadableDateFromUnix = (unix: number | undefined): Date | undefined => {
    if(!unix) return undefined;
    return new Date(unix * 1000);
}

export const getDayAfter = (day: Date):Date => {
    const data = moment(day); // crea un oggetto moment con la tua data
    const dayAfter = data.add(1, 'days'); // aggiungi un giorno alla data
    return dayAfter.toDate();
}

export const getDayBefore = (day: Date):Date => {
    const data = moment(day); // crea un oggetto moment con la tua data
    const dayAfter = data.subtract(1, 'days'); // sottrai un giorno alla data
    return dayAfter.toDate();
}

export function checkDateRangeValidity(data: any, alertService: AlertService ): boolean {
    return (!data?.dateRange?.from && !data?.dateRange?.to && !data?.dateRange?.startDate && !data?.dateRange?.endDate)
    ? (alertService.openErrorAlert('Custom date range must contain at least one of "Start date" or "End date"'),true )
    : false;
}
