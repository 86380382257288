import {Component, OnInit} from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";
import {AlertService} from "../../alert/alert.service";
import {IOption} from "../../interfaces/form.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MidSelectionService} from "../../../@core/services/mid-selection.service";
import {AuthUserService} from "../../../@core/services/auth/auth-user.service";
import {ERole} from "../../enum/role.enum";
import { tap } from 'rxjs';
import { TransactionService } from '../../../@core/services/transaction/transaction.service';
import { mapGroup, mapMerchant, mapMid, mapSuperMerchant } from '../../utils/actors.utils';

@Component({
  selector: 'ultrapay-mid-selection',
  templateUrl: './mid-selection.component.html',
  styleUrl: './mid-selection.component.scss'
})
export class MidSelectionComponent implements OnInit {
  filter: any
  superMerchantsOption: IOption[] = []
  merchantsOption: IOption[] = []
  groupsOption: IOption[] = []
  midsOption: IOption[] = []
  form: FormGroup = new FormGroup({
    superMerchant: new FormControl(null, Validators.required),
    merchant: new FormControl({value: null, disabled: true}, Validators.required),
    group: new FormControl({value: null, disabled: true}, Validators.required),
    mid: new FormControl({value: null, disabled: true}, Validators.required),
  })
  loading: boolean = false
  loadingMerchant: boolean = false
  loadingGroup: boolean = false
  loadingMid: boolean = false
  ERole = ERole;

  constructor(
    private modalRef: MdbModalRef<MidSelectionComponent>,
    private alertService: AlertService,
    private authUserService: AuthUserService,
    private midSelectionService: MidSelectionService,
    private transactionService: TransactionService
  ) {}

  ngOnInit() {
    if (this.filter?.superMerchant) {
      this.loadFilter();
    } else {
      this.transactionService.getActors().pipe(
        tap((res)=> {
          this.superMerchantsOption = mapSuperMerchant(res.data?.superMerchants);
          this.merchantsOption = res.data?.merchants ? mapMerchant(res.data.merchants) : [{label: this.authUserService.user.email, value:this.authUserService.user._id}];
          this.groupsOption = mapGroup(res.data?.midGroups);
          this.midsOption = mapMid(res.data?.mids);
          this.form.get('superMerchant')?.setValue(this.superMerchantsOption.map(sm => sm.value));
          this.form.get('merchant')?.setValue(this.merchantsOption.map(m => m.value));
          this.form.get('group')?.setValue(this.groupsOption.map(g => g.value));
          this.form.get('mid')?.setValue(this.midsOption.map(m => m.value));
          this.enableField(['superMerchant', 'merchant', 'group', 'mid']);
          this.initValueChange();
        })).subscribe();
    }
  }

  get role() {
		return this.authUserService.getRole()
	}

  initValueChange() {
    this.form.get('superMerchant')?.valueChanges.subscribe(res => {
      if (res && res.length > 0) this.getMerchant(res)
      else this.resetAndDisableFields(['merchant', 'group', 'mid'])
    })
    this.form.get('merchant')?.valueChanges.subscribe(res => {
      if (res && res.length > 0) this.getGroup(res)
      else this.resetAndDisableFields(['group', 'mid'])
    })
    this.form.get('group')?.valueChanges.subscribe(res => {
      if (res && res.length > 0) this.getMid(res)
      else this.resetAndDisableFields(['mid'])
    })
  }

  close(filter?: any) {
    this.modalRef.close(filter);
  }

  getSuperMerchant() {
    this.midSelectionService.getSuperMerchant().then(res => {
      this.superMerchantsOption = res
    }).catch(err => {
      this.alertService.openErrorAlert(err)
    })
  }

  getMerchant(smIds: string[]) {
    this.loadingMerchant = true
    this.resetAndDisableFields(['merchant', 'group', 'mid']);
    this.midSelectionService.getMerchant(smIds).then(res => {
      this.merchantsOption = res
      this.enableField(['merchant'])
      this.loadingMerchant = false
    }).catch(err => {
      this.alertService.openErrorAlert(err)
    })
  }

  getGroup(mIds: string[]) {
    this.loadingGroup = true
    this.resetAndDisableFields(['group', 'mid']);
    this.midSelectionService.getGroup(mIds).then(res => {
      this.groupsOption = res
      this.enableField(['group'])
      this.loadingGroup = false
    }).catch(err => {
      this.alertService.openErrorAlert(err)
    })
  }

  getMid(gIds: string[]) {
    this.loadingMid = true
    this.resetAndDisableFields(['mid']);
    this.midSelectionService.getMid(gIds).then(res => {
      this.midsOption = res
      this.enableField(['mid'])
      this.loadingMid = false
    }).catch(err => {
      this.alertService.openErrorAlert(err)
    })
  }

  async loadFilter() {
    try {
      const role: ERole = this.authUserService.getRole()!
      this.loading = true
      const fieldEnable = []
      this.superMerchantsOption = await this.midSelectionService.getSuperMerchant()
      fieldEnable.push('superMerchant')

      if (role === ERole.OPERATOR) {
        this.merchantsOption = [{value: this.authUserService.user._id, label: this.authUserService.user.email}]
        fieldEnable.push('merchant')
      } else if (this.filter.superMerchant?.length > 0) {
        this.merchantsOption = await this.midSelectionService.getMerchant(this.filter.superMerchant)
        fieldEnable.push('merchant')
      }

      if (this.filter.merchant?.length > 0) {
        this.groupsOption = await this.midSelectionService.getGroup(this.filter.merchant)
        fieldEnable.push('group')
      }

      if (this.filter.group?.length > 0) {
        this.midsOption = await this.midSelectionService.getMid(this.filter.group)
        fieldEnable.push('mid')
      }
      this.enableField(fieldEnable)
      fieldEnable.forEach(f => {
        this.form.get(f)?.setValue(this.filter[f])
      })
      this.loading = false
      this.initValueChange()
    } catch (e) {
      this.alertService.openErrorAlert(e as string)
    }
  }

  submitFilter() {
    const filter = {
      superMerchant: this.form.get('superMerchant')?.value,
      merchant: this.form.get('merchant')?.value,
      group: this.form.get('group')?.value,
      mid: this.form.get('mid')?.value
    }
    this.close(filter)
  }

  enableField(field: string[]) {
    field.forEach(f => {
      this.form.get(f)?.enable()
    })
    if(this.authUserService.getRole() === ERole.OPERATOR){
      this.form.get('superMerchant')?.disable()
      this.form.get('merchant')?.disable()
    }
  }

  resetAndDisableFields(field: string[]) {
    field.forEach(f => {
      this.form.get(f)?.reset();
      this.form.get(f)?.disable();
      if (f === 'superMerchant') {
        this.merchantsOption = []
        this.groupsOption = []
        this.midsOption = []
      } else if (f === 'merchant') {
        this.groupsOption = []
        this.midsOption = []
      } else if (f === 'group') {
        this.midsOption = []
      }
    })
  }
}
