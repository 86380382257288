import {Component, OnInit} from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";
import {filter, noop, take, tap} from 'rxjs';
import {TransactionService} from '../../../@core/services/transaction/transaction.service';
import {IExcelDownloadRequest, IExcelHeader, ITreeSelected} from '../../interfaces/transaction.interface';
import {FormControl, FormGroup} from '@angular/forms';
import {EExcelType} from "../../enum/download.enum";
import {excelExportRequest} from '../../utils/transactions.utils';
import {AuthUserService} from "../../../@core/services/auth/auth-user.service";
import {ERole} from "../../enum/role.enum";
import { EPageReference } from '../../enum/tab-type.enum';
import { DashboardFacade } from '../../facade/dashboard.facade';

@Component({
	selector: 'ultrapay-excel-export-modal',
	templateUrl: './excel-export-modal.component.html',
	styleUrl: './excel-export-modal.component.scss'
})
export class ExcelExportModalComponent implements OnInit {
	headersList: IExcelHeader;
	filters: any = null;
	form: FormGroup;
	SMDatas: any = []
	SMSelected: { email: string, fee: string }[] = []
	GDatas: any = []
	GSelected: { email: string, fee: string }[] = []
	excelHeadersPayload: any;

	constructor(private modalRef: MdbModalRef<ExcelExportModalComponent>, private transactionService: TransactionService, private authUserService: AuthUserService, private dashboardFacade: DashboardFacade) {
	}
	
	ngOnInit() {
		this.excelHeadersPayload = { pageReference: this.filters?.pageReference };
		// faccio questo if perchè solo nel caso di transactions cè l'obbligatorietà di passare i mid a getExcelHeaders
		if (this.filters.pageReference === EPageReference.TRANSACTION) {
			// se ho già i filtri nello state ci faccio lo spread this.excelHeadersPayload
			if ((this.filters?.formFilters?.midSelection?.group && this.filters?.formFilters?.midSelection?.superMerchant)) {
				this.excelHeadersPayload = {
					...this.excelHeadersPayload,
					midGroupIds: this.filters?.formFilters?.midSelection?.group,
					userSuperMerchantIds: this.filters?.formFilters?.midSelection?.superMerchant,
				}
				this.getExcelHeaders();
			} else { 
				// se non ho i filtri parte (in transactions)
				//this.dashboardFacade.loadAllMidSelection();
				// devo aspettare che li scriva nello state per procedere... per questo il filter, quando lo passa vuol dire che ho i dati e posso fare take 1
				this.dashboardFacade.allFilters$.pipe(
					filter((filters) => filters?.formFilters?.midSelection?.group && filters?.formFilters?.midSelection?.superMerchant),
					take(1),
					tap((filters) => {
						this.excelHeadersPayload = {
							...this.excelHeadersPayload,
							midGroupIds: filters?.formFilters?.midSelection?.group,
							userSuperMerchantIds: filters?.formFilters?.midSelection?.superMerchant,
						}
						this.getExcelHeaders();
					}
				)).subscribe(noop)
			}
			return;
		}
		this.getExcelHeaders();
	}

	getExcelHeaders() {
		this.transactionService.getExcelHeaders(this.excelHeadersPayload).pipe(tap((res) => {
			this.headersList = res.data;
			for (let key in this.headersList.userSuperMerchantFees) {
				if (this.headersList.userSuperMerchantFees[key].length === 0) continue;
				this.SMDatas.push([{
					name: key,
					checked: true,
					children: this.headersList.userSuperMerchantFees[key].map((value) => ({name: value})) as any[]
				}])
			}
			for (let key in this.headersList.midGroupFees) {
				if (this.headersList.midGroupFees[key].length === 0) continue;
				this.GDatas.push([{
					name: key,
					children: this.headersList.midGroupFees[key].map((value) => ({name: value})) as any[]
				}])
			}
			this.form = new FormGroup({});
			this.addFieldsToForm();
		})).subscribe(noop);
	}
	
	addFieldsToForm() {
		this.headersList.headers.forEach(field => {
			this.form.addControl(field.key, new FormControl(true));
		});
		this.form.addControl('format', new FormControl(EExcelType.XLSX));
	}
	
	close(status: boolean) {
		if (status) this.launchDownload();
		this.modalRef.close(status);
	}
	
	launchDownload() {
		const formValues = this.form.getRawValue();
		const checkedKeys = Object.keys(formValues).filter(key => formValues[key] === true);
		const filters = excelExportRequest(this.filters, this.authUserService);
		const request: IExcelDownloadRequest = {
			...filters,
			selectedFields: checkedKeys,
			...(this.SMSelected?.length > 0 ? {superMerchantFeesNames: this.SMSelected.map((value) => value.fee)} : {}),
			...(this.GSelected?.length > 0 ? {midGroupFeesNames: this.GSelected.map((value) => value.fee)} : {}),
			type: this.form.get('format')?.value,
			pageReference: this.filters?.pageReference,
		};
		
		if(this.authUserService.getRole() === ERole.OPERATOR){
			delete request.superMerchantFeesNames
		}
		
		this.transactionService.excelDownload(request).pipe(tap((res) => {
			const filename = 'export' + new Date().getTime() + `.${request.type}`;
			let dataType = res.type;
			let binaryData = [];
			binaryData.push(res);
			let downloadLink = document.createElement('a');
			downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
			if (filename)
				downloadLink.setAttribute('download', filename);
			document.body.appendChild(downloadLink);
			downloadLink.click();
		})).subscribe(noop);
	}
	
	smGSelected(e: ITreeSelected, email: string, arr: { email: string, fee: string }[]) {
		if (e.children) {
			for (let child of e.children) {
				if (!child.undefined) {
					arr = arr.filter((value) => !(value.email === email && value.fee === child.name))
				} else if (!arr.find((value) => value.email === email && value.fee === child.name)) {
						arr.push({email, fee: child.name})
				}
			}
		} else {
			if (!e.undefined) {
				arr.filter((value) => !(value.email === email && value.fee === e.name))
			} else if (!arr.find((value) => value.email === email && value.fee === e.name)) {
				arr.push({email, fee: e.name})
			}
		}
	}
}
