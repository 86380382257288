export enum TabType {
    DASHBOARD = 'report',
    TRANSACTION = 'transaction',
    USERS = 'users',
    REFUNDS = 'refund',
    DISPUTES = 'disputes',
    MID_LIST = 'mid-list',
    GROUP_LIST = 'group-list',
    MID_MANAGEMENT = 'mid-management'
}

export enum EPageReference { //TO BE REMOVED
    TRANSACTION = 'transaction',
    REFUND ='refund',
    REPORT = 'report'
}