<div class="modal-header">
  <h5 class="modal-title">Select data to export</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close(false)"
  ></button>
</div>

<div class="modal-body">
  @if (form) {
    <div class="row g-3" [formGroup]="form">
      <div class="col-12">
        <p class="m-0">Choose headers</p>
      </div>
      @for (header of headersList.headers; track $index) {
        <div class="col-3">
          <div class="form-check">
            <input
              mdbCheckbox
              class="form-check-input"
              type="checkbox"
              [value]="header.key"
              [id]="header.key"
              [checked]="true"
              [formControlName]="header.key"
            />
            <label class="form-check-label" [for]="header.key">
              {{ header.label }}
            </label>
          </div>
        </div>
      }
      @if (SMDatas && SMDatas.length > 0) {
        <div class="col-12">
          <p class="m-0">Choose Super merchant fee</p>
        </div>
        <div class="col-12">
          <div class="row">
            @for (dataSM of SMDatas; track $index) {
              <div class="col-6">
                <mdb-treeview
                  textField="name"
                  childrenField="children"
                  [nodes]="dataSM"
                  [selectable]="true"
                  [line]="true"
                  (selected)="smGSelected($event, dataSM[0].name, SMSelected)"
                ></mdb-treeview>
              </div>
            }
          </div>
        </div>
      }
      @if (GDatas && GDatas.length > 0) {
        <div class="col-12">
          <p class="m-0">Choose Group fee</p>
        </div>
        <div class="col-12">
          <div class="row">
            @for (dataG of GDatas; track $index) {
              <div class="col-6">
                <mdb-treeview
                  textField="name"
                  childrenField="children"
                  [nodes]="dataG"
                  [selectable]="true"
                  [line]="true"
                  (selected)="smGSelected($event, dataG[0].name, GSelected)"
                ></mdb-treeview>
              </div>
            }
          </div>
        </div>
      }
      <p class="mt-3 mb-1">Choose format</p>
      <div class="btn-group">
        <input
          mdbRadio
          type="radio"
          class="btn-check"
          name="format"
          id="xlsx"
          autocomplete="off"
          [checked]="true"
          formControlName="format"
          value="xlsx"
        />
        <label class="btn btn-primary" for="xlsx">Xlsx</label>
        <input
          mdbRadio
          type="radio"
          class="btn-check"
          name="format"
          id="csv"
          autocomplete="off"
          formControlName="format"
          value="csv"
        />
        <label class="btn btn-primary" for="csv">Csv</label>
      </div>
    </div>
  } @else {
    <div class="text-center">
      <ultrapay-spinner></ultrapay-spinner>
    </div>
  }
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="close(false)"
  >Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="close(true)"
  >Export
  </button>
</div>
