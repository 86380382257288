import { AuthUserService } from "../../@core/services/auth/auth-user.service";
import { DashboardState } from "../../store/dashboard/dashboard.reducer";
import {IExcelDownloadFilters, ITransactionRequest} from "../interfaces/transaction.interface";

function calculateAmountRange(filters: any) {
  if (filters?.formFilters?.amountMin || filters?.formFilters?.amountMax) {
    return {
      amountRange: {
        ...(filters.formFilters.amountMin ? {min: filters.formFilters.amountMin} : {}),
        ...(filters.formFilters.amountMax ? {max: filters.formFilters.amountMax} : {}),
      }
    };
  }
  return {};
}

export const mapFiltersToRequest = (filters: any, authUserService: AuthUserService): ITransactionRequest => {
  return {
    pagination: {
      page: filters?.pagination?.page ? filters.pagination.page : 1,
      size: filters?.pagination?.size ? filters.pagination.size : 10,
    },
    ...(filters?.formFilters?.transactionId ? {transactionId: filters.formFilters.transactionId} : {}),
    ...(filters?.formFilters?.refundId ? {refundId: filters.formFilters.refundId} : {}),
    ...calculateAmountRange(filters),
    ...(filters?.formFilters?.email ? {email: filters.formFilters.email} : {}),
    dateRange: {
      ...(filters?.dateRange?.startDate ? {from: filters.dateRange.startDate} : {}),
      ...(filters?.dateRange?.endDate ? {to: filters.dateRange.endDate} : {}),
    },
    ...(filters?.formFilters?.status ? {status: filters.formFilters.status} : {}),
    ...(filters?.formFilters?.voucherToken ? {voucherToken: filters.formFilters.voucherToken} : {}),
    ...(filters?.formFilters?.currency ? {currency: filters.formFilters.currency} : {currency: authUserService?.user?.defaultCurrency ?? null}),
    ...(filters?.formFilters?.pageReference ? {pageReference: filters.formFilters.pageReference} : {}),
    ...(filters?.formFilters?.relativeTransactions ? {
      relativeTransactions: {
        ids: filters.formFilters.relativeTransactions.ids,
        type: filters.formFilters.relativeTransactions.type
      }
    } : {}),
    ...(filters?.docId ? {docId: filters.docId} : {}),
  };
};

export const excelExportRequest = (filters: DashboardState, authUserService: AuthUserService): IExcelDownloadFilters => {
  return {
    ...(filters?.formFilters?.transactionId ? {transactionId: filters?.formFilters?.transactionId} : {}),
    ...(filters?.formFilters?.amountMin ?? filters?.formFilters?.amountMax ? {
      amountRange: {
        ...(filters?.formFilters?.amountMin ? {min: filters?.formFilters?.amountMin} : {}),
        ...(filters?.formFilters?.amountMax ? {max: filters?.formFilters?.amountMax} : {}),
      }
    } : {}),
    ...(filters?.formFilters?.email ? {email: filters?.formFilters?.email} : {}),
    dateRange: {
      ...(filters?.dateRange?.startDate ? {from: filters.dateRange.startDate} : {}),
      ...(filters?.dateRange?.endDate ? {to: filters.dateRange.endDate} : {}),
    },
    ...(filters?.formFilters?.status ? {status: filters?.formFilters?.status} : {}),
    ...(filters?.formFilters?.currency ? {currency: filters?.formFilters?.currency} : {currency: authUserService?.user?.defaultCurrency ?? null}),
  };
};
