import { Component } from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
  title: string | null = null;
  body: string | null = null;
  actionsLabel?:{
    cancel: string;
    confirm: string;
  };

  constructor(private modalRef: MdbModalRef<ConfirmModalComponent>) { }


  close(status?: boolean){
    this.modalRef.close(status);
  }

}
