import {Injectable} from '@angular/core';
import {MdbModalRef, MdbModalService} from 'mdb-angular-ui-kit/modal';
import {Subject, take, tap} from 'rxjs';
import {ConfirmModalComponent} from "../../@shared/modals/confirm-modal/confirm-modal.component";
import {IModalConfig} from "../../@shared/interfaces/modal.interface";
import {LoadingModalComponent} from "../../@shared/modals/loading-modal/loading-modal.component";
import { ExcelExportModalComponent } from '../../@shared/modals/excel-export-modal/excel-export-modal.component';
import {TextComponent} from "../../@shared/modals/text/text.component";
import {CreateFeeComponent} from "../../@shared/modals/create-fee/create-fee.component";
import { ApiKeyComponent } from '../../@shared/modals/api-key/api-key.component';
import {MidSelectionComponent} from "../../@shared/modals/mid-selection/mid-selection.component";
import { AlertService } from '../../@shared/alert/alert.service';
import { checkDateRangeValidity } from '../../@shared/utils/date.utils';
import { DashboardFacade } from '../../@shared/facade/dashboard.facade';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
  private loadingModal!: MdbModalRef<LoadingModalComponent>;
  private textModal!: MdbModalRef<TextComponent>;
  private confirmModal!: MdbModalRef<ConfirmModalComponent>;
  private excelExportModal: MdbModalRef<ExcelExportModalComponent>;
  private createFee!: MdbModalRef<CreateFeeComponent>;
  private apiKey!: MdbModalRef<ApiKeyComponent>;
  private midSelectionModal!: MdbModalRef<MidSelectionComponent>;


  loadingText$: Subject<string | undefined> = new Subject();

	constructor(private modalService: MdbModalService, private alertService: AlertService, private dashboardFacade: DashboardFacade) {
	}

  openModalText(title: string, body: string, status?: 'success' | 'error' | 'warning' | 'info' | 'default', size: 'sm' | 'md' | 'lg' = 'sm', textAlign: 'center' | 'left' | 'right' = 'center', btnShow = false, btnText = '') {
    let sizeClass;
    switch (size) {
      case 'sm':
          sizeClass = 'modal-sm';
          break;
      case 'md':
          sizeClass = 'modal-md';
          break;
      default:
          sizeClass = 'modal-lg';
    }
    this.textModal = this.modalService.open(TextComponent, {
      modalClass: 'modal-dialog-centered ' + sizeClass,
      ignoreBackdropClick: true,
      data: {title, body, status, textAlign, btnShow, btnText}
    });
    return this.textModal.onClose
  }


  openConfirmModal(data: IModalConfig) {
    let sizeClass;
    switch (data.size) {
        case 'sm':
            sizeClass = 'modal-sm';
            break;
        case 'md':
            sizeClass = 'modal-md';
            break;
        default:
            sizeClass = 'modal-lg';
  }
    this.confirmModal = this.modalService.open(ConfirmModalComponent, {
      modalClass: 'modal-dialog-centered '+ sizeClass,
      ignoreBackdropClick: true,
      data: {title: data.title, body: data.body, actionsLabel: data.actionsLabel}
    });
    return this.confirmModal.onClose;
  }

  openLoadingModal(text?: string) {
    this.loadingText$ = new Subject();
    this.loadingModal = this.modalService.open(LoadingModalComponent, {
      modalClass: 'modal-dialog-centered modal-sm',
      ignoreBackdropClick: true,
      data: {text}
    });
    return {
      update: (text: string) => this.loadingText$.next(text),
      close: () => {
        this.loadingModal?.close();
        this.loadingText$.complete();
      }
    }
  }

  openExcelExportModal() {
    this.dashboardFacade.allFilters$.pipe(
      take(1),
      tap((filters) => {
        if (checkDateRangeValidity(filters, this.alertService)) return;// se non cè almeno from o to non faccio partire la chiamata
        this.excelExportModal = this.modalService.open(ExcelExportModalComponent, {
          modalClass: 'modal-dialog-centered modal-lg',
          ignoreBackdropClick: false,
          data: {filters: filters}
        });
        return this.excelExportModal.onClose;
      })).subscribe();
	}

  closeExcelExportModal() {
    this.excelExportModal?.close();
  }

  openModalCreateFee() {
    this.createFee = this.modalService.open(CreateFeeComponent, {
      modalClass: 'modal-dialog-centered md',
      ignoreBackdropClick: true,
      data: {}
    });
    return this.createFee.onClose
  }

  openApiKeyModal(apiKey: string) {
    this.apiKey = this.modalService.open(ApiKeyComponent, {
      modalClass: 'modal-dialog-centered md',
      ignoreBackdropClick: true,
      data: {apiKey}
    });
    return this.apiKey.onClose
  }

  openModalMidSelection(filter?: any) {
    this.midSelectionModal = this.modalService.open(MidSelectionComponent, {
      modalClass: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick: true,
      data: {filter: filter}
    });
    return this.midSelectionModal.onClose
  }

}
