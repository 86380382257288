<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>

<div class="modal-body text-center" [innerHtml]="body"></div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="close(false)"
  >{{actionsLabel?.cancel || 'No'}}
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    (click)="close(true)"
  >{{actionsLabel?.confirm || 'Yes'}}
  </button>
</div>
