export const mapSuperMerchant = (data: any[]) => {
    return data.map(x => ({ value: x._id, label: x.email }));
}

export const mapMerchant = (data: any[]) => {
    let merchantsOption: any[] = [];
    data.forEach(x => {
        merchantsOption = [...merchantsOption, ...x.data.map((x: any) => ({ value: x.id, label: x.email }))];
    });
    return merchantsOption;
}

export const mapOperator = (data: any[]) => {
    let operatorsOption: any[] = [];
    data.forEach(x => {
        operatorsOption = [...operatorsOption, ...x.data.map((x: any) => ({ value: x.id, label: x.email }))];
    });
    return operatorsOption;
}

export const mapGroup = (data: any[]) => {
    let groupsOption: any[] = [];
    data.forEach(x => {
        groupsOption = [...groupsOption, ...x.data.map((x: any) => ({ value: x.id, label: x.name }))];
    });
    return groupsOption;
}

export const mapMid = (data: any[]) => {
    let midsOption: any[] = [];
    data.forEach(x => {
        midsOption = [...midsOption, ...x.data.map((x: any) => ({ value: x.id, label: `${x.name !== '' ? x.name : 'N.A.'} - ${x.code}` }))];
    });
    return midsOption;
}
