<div class="modal-header"
     [class.alert-success]="status === 'success'"
     [class.alert-error]="status === 'error'"
     [class.alert-warning]="status === 'warning'"
     [class.alert-info]="status === 'info'"
>
  <h5 class="modal-title">{{title}}</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>

<div class="modal-body text-center word-break-word"
     [class.text-center]="textAlign === 'center'"
      [class.text-start]="textAlign === 'left'"
      [class.text-end]="textAlign === 'right'"
     [innerHtml]="body"></div>

<div class="modal-footer" *ngIf="btnShow">
  <button type="button" class="btn btn-primary btn-sm btn-block" (click)="close()">{{btnText}}</button>
</div>
