import { Injectable } from '@angular/core';
import {MdbNotificationRef, MdbNotificationService} from 'mdb-angular-ui-kit/notification';
import {AlertComponent} from "./alert.component";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private notificationRef: MdbNotificationRef<AlertComponent> | null = null;

  constructor(private notificationService: MdbNotificationService) {
  }

  openErrorAlert(text: string) {
    this.notificationRef = this.notificationService.open(AlertComponent, {
      data: {type: 'danger', text},
      stacking: true,
      autohide: true
    });
  }

  openSuccessAlert(text: string) {
    this.notificationRef = this.notificationService.open(AlertComponent, {
      data: {type: 'success', text},
      stacking: true,
      autohide: true
    });
  }
}
