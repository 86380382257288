import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from '../../alert/alert.service';

@Component({
  selector: 'ultrapay-api-key',
  templateUrl: './api-key.component.html',
  styleUrl: './api-key.component.scss'
})
export class ApiKeyComponent {
  apiKey: string;
  constructor(private modalRef: MdbModalRef<ApiKeyComponent>, private clipboard: Clipboard, private alertService: AlertService) {
  }

  close(){
    this.modalRef.close();
  }

  copyKey() {
    this.clipboard.copy(this.apiKey);
    this.alertService.openSuccessAlert('ApiKey copied to clipboard');
  }

}
