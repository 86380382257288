<div class="up-api-key-modal">
    <div class="modal-header">
        <h5 class="modal-title">Api Key</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="close()"
        ></button>
    </div>

    <div class="modal-body text-center word-break-word">
        Key generated successfully, copy the key before closing the modal<br>
        <br>Key:<br>
        <div class="d-flex justify-content-center align-items-center">
            <strong class="up-api-key-modal__code">{{apiKey}}</strong>
            <button type="button" class="up-api-key-modal__icon btn btn-link btn-sm ms-1" mdbTooltip="Copy apikey"
                (click)="copyKey()">
                <i class="fa-solid fa-copy"></i>
            </button>
        </div>

    </div>
</div>
