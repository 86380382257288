import { Component, AfterViewInit } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements AfterViewInit {
  type: string | null = null;
  text: string = '';
  progress = 100;

  constructor(public notificationRef: MdbNotificationRef<AlertComponent>) {
  }

  ngAfterViewInit() {
    const interval = setInterval(() => {
      this.progress--;
      if (this.progress === 0) {
        clearInterval(interval);
      }
    }, 50);
  }

  close(){
    this.notificationRef.close();
  }
}
